import { GenericLazyString } from '@eventbrite/i18n';
import { TagObject, Tags } from '@eventbrite/tags';
import React from 'react';

import './ThingsToDoShelf.scss';

export interface ThingsToDoShelfProps {
    /**
     * List of tags object
     */
    tags: TagObject[];
    /**
     * Header of the Things To Do Shelf - contains title and url
     */
    header: {
        url?: string;
        title: string | GenericLazyString;
    };
    /**
     * Custom Background Color for Things To Do Shelf
     */
    shelfBackgroundColor?: string;
    /**
     * Custom Styling for Things To Do Shelf
     */
    shelfClassName?: string;
    /**
     * Tags custom styling configuration object
     */
    tagsConfig?: {
        backgroundColor?: string;
        tagColor?: string;
        tagHoverColor?: string;
        className?: string;
    };
}

const RenderHeader = (header: ThingsToDoShelfProps['header']) => {
    if (header.url) {
        return (
            <a className="discover-ttd-shelf__link" href={header.url}>
                <h2 className="discover-ttd-shelf__title">{header.title}</h2>
            </a>
        );
    } else {
        return <h2 className="discover-ttd-shelf__title">{header.title}</h2>;
    }
};

/**
 * Things To Do Shelf Component
 */
export const ThingsToDoShelf: React.FunctionComponent<ThingsToDoShelfProps> = ({
    tags,
    header,
    shelfClassName = '',
    tagsConfig = {},
    shelfBackgroundColor = '#f8f7fa',
}) => {
    return (
        <div
            className={`discover-ttd-shelf ${shelfClassName}`}
            style={{ backgroundColor: shelfBackgroundColor }}
        >
            <RenderHeader url={header.url} title={header.title} />
            <Tags tags={tags} {...tagsConfig} />
        </div>
    );
};
